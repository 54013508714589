import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SearchBar from "../Search/SearchBar"
import RevealWrapper from "../RevealWrapper/RevealWrapper"
import HeaderText from "../SVG/HeaderText"

const HeroHome = ({
  headingPrimary,
  headingSecondary,
  placeholder,
  imagePrimary,
  imageSecondary,
  imageTertiary,
}) => {
  let imageMobile = null
  let imageTablet = null
  let imageDesktop = null

  if (imageTertiary.length) {
    imageTablet = getImage(imageTertiary[0].localFile)
  }
  if (imageSecondary.length) {
    imageMobile = getImage(imageSecondary[0].localFile)
  }
  if (imagePrimary.length) {
    imageDesktop = getImage(imagePrimary[0].localFile)
  }

  return (
    <div className="relative -mt-shh">
      <div className="h-hero-height min-h-170">
        <div className="h-full md:hidden">
          <GatsbyImage
            className="h-full w-full"
            image={imageMobile}
            alt={imageSecondary[0].alt || headingPrimary}
            objectFit="cover"
          />
        </div>
        <div className="hidden h-full md:block lg:hidden">
          <GatsbyImage
            className="h-full w-full"
            image={imageTablet}
            alt={imageTertiary[0].alt || headingPrimary}
            objectFit="cover"
          />
        </div>
        <div className="hidden h-full lg:block">
          <GatsbyImage
            className="h-full w-full"
            image={imageDesktop}
            alt={imagePrimary[0].alt || headingPrimary}
            objectFit="cover"
          />
        </div>
        <div className="hero-gradient-mobile lg:hero-gradient-desktop absolute top-0 left-0 h-full w-full"></div>

        <div className="absolute mb-12 lg:mb-0 lg:ml-20 inset-0 z-20 flex w-full flex-col items-center justify-end lg:justify-center text-center lg:w-125 lg:gap-16">
          {headingPrimary && (
            <RevealWrapper>
              <h1 className="w-80 mb-8 lg:mb-0 lg:w-104">
                <HeaderText />
              </h1>
            </RevealWrapper>
          )}
          <SearchBar heading={headingSecondary} placeholder={placeholder} />
        </div>
      </div>
    </div>
  )
}

HeroHome.propTypes = {
  headingPrimary: PropTypes.string.isRequired,
  headingSecondary: PropTypes.string.isRequired,
  imagePrimary: PropTypes.array.isRequired,
  imageSecondary: PropTypes.array.isRequired,
  imageTertiary: PropTypes.array.isRequired,
}

export default HeroHome
