import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Autocomplete } from "@react-google-maps/api"
import { navigate, Link } from "gatsby"
import { useGoogleApiContext } from "../GoogleApi/GoogleApiWrapper"

import IconPin from "../SVG/IconPin"
import "./SearchBar.css"

const SearchBar = ({ heading, placeholder }) => {
  const { isLoaded } = useGoogleApiContext()

  const [autocomplete, setAutocomplete] = useState(null)
  const [lat, setLat] = useState(null)
  const [lng, setLng] = useState(null)

  // As soon as a place is selected, the user is
  // routed to the search page with the corresponding query
  // params
  useEffect(() => {
    if (lat && lng) navigate(searchUrl())

    return () => {
      // Removes the element added by the Google Autocomplete component
      // whenever is it instanciated on page load (new window.google.maps.places.Autocomplete)
      if (document !== undefined) {
        const autocompleteDiv = document.getElementsByClassName(
          "pac-container pac-logo hdpi"
        )
        if (autocompleteDiv.length) autocompleteDiv[0].remove()
      }
    }

    /* eslint-disable-next-line */
  }, [lat, lng])

  // The url to send the user to on submit
  const searchUrl = () => {
    const baseUrl = "/search-results"
    return lat && lng ? baseUrl + `?lat=${lat}&lng=${lng}` : baseUrl
  }

  // When the autocomplete loads
  const onLoad = input => {
    setAutocomplete(input)
  }

  // When a place is chosen from the available dropdown
  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      setLat(autocomplete?.getPlace()?.geometry?.location?.lat())
      setLng(autocomplete?.getPlace()?.geometry?.location?.lng())
    }
  }

  return (
    <div className="w-5/6 overflow-hidden rounded-2xl md:w-125">
      <div className="bg-blue-500 px-2 py-4 text-center text-md font-bold text-white sm:text-base md:text-md lg:text-lg">
        {heading && <h2>{heading}</h2>}
      </div>

      <div className="flex w-full flex-col items-center  rounded-b-md bg-white py-3 md:flex-row md:px-6 md:py-4">
        <div className="ml-10 flex w-full items-center pb-2 text-blue-500 md:ml-0 md:pb-0">
          <IconPin className="h-7 w-6" />
          {isLoaded ? (
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
              types={["(regions)"]}
              restrictions={{ country: ["AU"] }}
              fields={["formatted_address", "address_component", "geometry"]}
              className="w-full"
            >
              <input
                type="text"
                className="focus:outline-none ml-2 h-10 w-full text-sm font-semibold text-blue-500 placeholder-blue-500 placeholder-opacity-50 focus:placeholder-opacity-60 lg:ml-4 lg:h-12 lg:text-base"
                placeholder={placeholder}
              />
            </Autocomplete>
          ) : (
            <div className="focus:outline-none flex h-10 w-full items-center text-base font-semibold text-blue-500 placeholder-blue-500 focus:placeholder-opacity-60 lg:h-12 lg:text-md">
              Loading...
            </div>
          )}
        </div>
        <hr className="w-full border-t border-blue-400 pb-3 md:hidden"></hr>
        <Link
          to={searchUrl()}
          className="SearchBar-button Button Button--primary"
        >
          <span className="lg:hidden">
            <button className="bg-blue-500">Search</button>
          </span>
          <span className="sr-only lg:not-sr-only">Search</span>
        </Link>
      </div>
    </div>
  )
}

SearchBar.propTypes = {
  heading: PropTypes.string,
}

export default React.memo(SearchBar)
