import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import HeroHome from "../components/Hero/HeroHome"
import FlexibleContent from "../components/FlexibleContent"

const IndexPage = ({ data, location }) => {
  const {
    theme,
    metaTitle,
    metaDescription,
    headingPrimary,
    headingSecondary,
    placeholder,
    imagePrimary,
    imageSecondary,
    imageTertiary,
    flexibleContent,
  } = data.craftHomeHomeEntry

  return (
    <Layout
      title={metaTitle}
      description={metaDescription}
      theme={theme}
      location={location}
    >
      <div className="js-transition-link-target">
        <div id="hero">
          <HeroHome
            headingPrimary={headingPrimary}
            headingSecondary={headingSecondary}
            imagePrimary={imagePrimary}
            imageSecondary={imageSecondary}
            imageTertiary={imageTertiary}
            placeholder={placeholder}
          />
        </div>
        <FlexibleContent flexibleContent={flexibleContent} />
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    craftHomeHomeEntry {
      title
      theme
      metaTitle
      metaDescription
      headingPrimary
      headingSecondary
      placeholder
      imagePrimary {
        ... on Craft_images_Asset {
          ...imageFragment
        }
      }
      imageSecondary {
        ... on Craft_images_Asset {
          ...imageFragment
        }
      }
      imageTertiary {
        ... on Craft_images_Asset {
          ...imageFragment
        }
      }
      flexibleContent {
        ...flexibleContentFragment
      }
    }
  }
`
