import React from "react"

const IconPin = () => {
  return (
    <svg
      height="25"
      viewBox="0 0 25 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 0C9.18642 0.00529624 6.01007 1.32364 3.66702 3.66615C1.32396 6.00865 0.0052975 9.18424 0 12.497C0 18.0246 7.67228 28.8393 10.9776 33.2253C11.1523 33.4653 11.3813 33.6605 11.6459 33.7952C11.9104 33.9298 12.2031 34 12.5 34C12.7969 34 13.0896 33.9298 13.3541 33.7952C13.6187 33.6605 13.8477 33.4653 14.0224 33.2253C17.3277 28.8393 25 18.0246 25 12.497C24.9947 9.18424 23.676 6.00865 21.333 3.66615C18.9899 1.32364 15.8136 0.00529624 12.5 0ZM12.5 17.3036C11.5491 17.3036 10.6196 17.0217 9.82899 16.4935C9.03837 15.9654 8.42215 15.2147 8.05827 14.3364C7.69439 13.4581 7.59918 12.4917 7.78469 11.5593C7.97019 10.6269 8.42808 9.7705 9.10045 9.09829C9.77282 8.42608 10.6295 7.9683 11.5621 7.78284C12.4947 7.59738 13.4613 7.69256 14.3398 8.05636C15.2183 8.42016 15.9692 9.03622 16.4975 9.82666C17.0257 10.6171 17.3077 11.5464 17.3077 12.497C17.3077 13.7718 16.8012 14.9944 15.8996 15.8958C14.9979 16.7972 13.7751 17.3036 12.5 17.3036Z"
        fill="#2F6D8A"
      />
    </svg>
  )
}

export default IconPin
